<template>
    <div>
		<page-title :heading=heading :subheading=subheading></page-title>
		

		<a-card :bordered="false" class="header-solid px-5 mb-5 pb-1" :bodyStyle="{padding: 0, paddingTop: '16px', marginBottom: '90px !important'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">Batch Details</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					<a-button type="primary" :href="`/request-management/drug-batches`" class="">
						<a-icon type="arrow-left" theme="outlined" class="text-white mr-0" />Back
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>
			
			<div class="pb-0 mb-0">
			
				<a-row :gutter="24">
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Batch Number</strong></label>
						<a-input 
							disabled
							v-model="stockDetails.batchNumber"
							/>
					</a-col>

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Entry Date</strong></label>
						<a-input 
							disabled
							v-model="stockDetails.entryDate"
							/>
					</a-col>
				</a-row>


				<a-row :gutter="24">
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Quantity</strong></label>
						<a-input 
							disabled
							v-model="stockDetails.quantity"
							/>
					</a-col>

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Selling Price</strong></label>
						<a-input 
							disabled
							v-model="stockDetails.sellingPrice"
							/>
					</a-col>
				</a-row>


				<a-row :gutter="24">

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Total Buying Price</strong></label>
						<a-input 
							disabled
							v-model="stockDetails.totalBuyingPrice"
							/>
					</a-col>

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Manufactured Date</strong></label>
						<a-input 
							disabled
							v-model="stockDetails.manufacturedDate"
							/>
					</a-col>

				</a-row>

				<a-row :gutter="24">

					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Expiry Date</strong></label>
						<a-input 
							disabled
							v-model="stockDetails.expiryDate"
							/>
					</a-col>


					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Status</strong></label>
						<a-input 
							disabled
							v-model="stockDetails.status"
							/>
					</a-col>

				</a-row>
				
			</div>
		</a-card>
		
		
		<a-card :bordered="false" class="header-solid px-5 mt-5" :bodyStyle="{marginTop: '80px'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">Request Tracking</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>
			
			<div class="">
				<a-row type="flex" :gutter="24" class="pb-4">
					<a-col :span="24" :md="24">
						<a-table class="mt-20 pb-2"  
							size="middle"
							:responsive="true"
							:bordered="false"
							:columns="columns" 
							:data-source="records" 
							:rowKey="record => record.uuid"
							:loading="pagination.loading" 
							:pagination="false">

								<template slot="s_no" slot-scope="s_no, record, index">
									{{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
								</template>

								<template slot="stage" slot-scope="stage, record">
									{{record.isActive ? record.stage.nextStatus : `${ record.status[0].toUpperCase() }${ record.status.substring(1) } ${record.stage.currentStatus}` }}
								</template>

								<template slot="sellingPrice" slot-scope="sellingPrice">
									{{ sellingPrice.toLocaleString() }}
								</template>

								<template slot="totalBuyingPrice" slot-scope="totalBuyingPrice">
									{{ totalBuyingPrice.toLocaleString() }}
								</template>

								<template slot="status" slot-scope="status">
									<a-badge  
										style="font-size: 12px !important; padding: 2px 5px;" 
										:count="`${status[0].toUpperCase()}${status.substring(1)}`" 
										:number-style="status == 'approved' ? { backgroundColor: '#52C41A', color: '#FFFFFF'} : status == 'rejected' ? { backgroundColor: '#F44335', color: '#FFFFFF'} : status == 'submitted' || status == 'created' ? { backgroundColor: '#FB8C00', color: '#FFFFFF'} : { backgroundColor: '#D9D9D9', color: '#444', boxShadow: '0 0 0 1px #d9d9d9 inset'}"/>
								</template>

								<template slot="createdAt" slot-scope="createdAt">
									{{ $Moment(createdAt).format("DD MMM YYYY") }}
								</template>

								<template slot="action" slot-scope="action, record">
									
									<a-button v-if="record.isActive && record.stage.stageNumber == 3 && hasPermission" class="pb-0 pt-0 txtBtn" id="txtBtn" type="text" style="color: #F44335;" @click="onLaunchRejectTrackingModal(record)">
										<strong>Reject</strong>
									</a-button>
									&nbsp;&nbsp;
									<a-button v-if="record.isActive && record.stage.stageNumber == 3" id="txtBtn" type="text" class="pb-0 pt-0 txtBtn text-success" style="" @click="onLaunchApprovalTrackingModal(record)">
										<strong>Approve</strong>
									</a-button>
								</template>
							
						</a-table>
					</a-col>

				</a-row>
			</div>
		</a-card>




		<!-- Tracking Approval Modal -->
        <a-modal v-model="tracking.showModal" :footer="null">
            <h4 class="text-center">Add Comments</h4>

            <form @submit.prevent="onSubmitTrackingApproval">

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Comments"
					:colon="false">
						<a-textarea
							style="height: 60px !important;"
							name="comments"
							:rows="4"
							placeholder="Comments"
							v-model="tracking.comments"
							/>
				</a-form-item>
                
				<a-form-item class="my-0 mt-4 pt-2 pb-2"> 
					<a-button type="primary" :loading="tracking.loading" html-type="submit" class="">
						Submit
					</a-button>
				</a-form-item>
					
            </form>
        </a-modal>


		<!-- Tracking Modal Rejection-->
        <a-modal v-model="trackingRejection.showModal" :footer="null">
            <h4 class="text-center">Add Comments</h4>

            <form @submit.prevent="onSubmitTrackingRejection">

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Comments"
					:colon="false">
						<a-textarea
							style="height: 60px !important;"
							name="comments"
							:rows="4"
							placeholder="Comments"
							v-model="trackingRejection.comments"
							/>
				</a-form-item>
                
				<a-form-item class="my-0 mt-4 pt-2 pb-2"> 
					<a-button type="primary" :loading="tracking.loading" html-type="submit" class="">
						Submit
					</a-button>
				</a-form-item>
					
            </form>
        </a-modal>

    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    const columns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
			// width: "5%"
        },
		{
			title: 'STAGE NUMBER',
			dataIndex: 'stage.stageNumber',
			// //width: "15%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'STAGE',
			dataIndex: 'stage',
			scopedSlots: { customRender: 'stage' },
			// //width: "15%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'COMMENTS',
			dataIndex: 'comments',
			// width: "13%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'STATUS',
			dataIndex: 'status',
			scopedSlots: { customRender: 'status' },
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'ACTION',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			// width: "30%"
			// align: "center"
		},
	];


    const rules = {
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ],
    };
  
    export default {
		components: {
			PageTitle,
		},
		data() {
			return {
            heading: 'Settings',
            subheading: 'Medicine Types',
            icon: 'pe-7s-drawer icon-gradient bg-happy-itmeo',
    
            fields: [ 'first_name', 'last_name', 'age' ],
            items: [
            { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
            { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
            { age: 89, first_name: 'Geneva', last_name: 'Wilson' }
            ],
            striped: false,
            bordered: false,
            outlined: false,
            small: false,
            hover: false,
            dark: false,
            fixed: false,
            footClone: false,

            pagination: {
                search: '',
                loading: false,
                perPage: 10,
                currentPage: 1,
                perPageOptions: [
                    {label: "10", value: 10}, 
                    {label: "20", value: 20}, 
                    {label: "30", value: 30}, 
                    {label: "50", value: 50}
                ],
                total: 10
            },

            columns,
            message: '',

            records: [],
			medicineRoutes: [],
			medicineTypes: [],
			medicineUnits: [],
            
            form: '',
            rules,

			stockDetails: {
				uuid: null,
				batchNumber: null,
				quantity: null,
				entryDate: null,
				sellingPrice: null,
				totalBuyingPrice: null,
				manufacturedDate: null,
				expiryDate: null,
				status: null,
                isCreate: true,
                loading: false,
                showModal: false,
			},

            newItem: {
                uuid: null,
                name: null,
                companyUuid: null,
                codeName: null,
                description: null,
                isCreate: true,
                loading: false,
                showModal: false,
            },

            deleteDataItem: {
                uuid: null,
                showModal: false,
            },

            userDetails: {
                user: null,
                company: null
            },

			tracking: {
				isCreate: true,
				showModal: false,
				loading: false,
				trackingUuid: null,
				status: "approved",
				comments: "",
				nextStageNumber: 0,
			},

			trackingRejection: {
				isCreate: true,
				showModal: false,
				loading: false,
				trackingUuid: null,
				status: "rejected",
				comments: "",
				nextStageNumber: 0,
			},

			hasPermission: true,

            notify: {
                text: null,
                type: null,
            },
        }
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'newItemForm' });
		},
      computed: {
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
        },
		watch: {
            pagination: {
                handler() {
                    this.getPharmacyMedicines();
                },
            },
            deep: true,
        },
		created() {
			this.form = this.$form.createForm(this, { name: 'newItemForm' })
            this.getUserDetails();
			this.getBatchDetails();
			this.getTrackings();
		},
		methods: {

            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("pharmUser"));

                if(userDetails != null) {
                    this.userDetails.user = userDetails.user;
                    this.userDetails.company = userDetails.company;
                }else {
                    this.$router.push(`/`);
                }

				// const role = userDetails != null && userDetails.role != null ? userDetails.role.codeName : null;

                // if(role == null) {
                //     this.$router.push(`/`);
                // }

                // let permissions = user.role.permissions !== null ? user.role.permissions.map(perm => perm.codeName.toLowerCase()) : [];

                // if(permissions != null && permissions.length > 0) {

                //     if(permissions.includes("authorize-drug")) {
                //         this.hasPermission = true;
                //     }else{
                //         this.hasPermission = false
                //     }
                // }
            },


			getActiveStage() {
                let show = false;
                let tracker = this.records.filter(track => track.isActive && track.stage.stageNumber == 3)

                if(tracker.length == 1) {
                    show = true;
                }

                return show
            },


			async getBatchDetails() {
                
                let url = `${this.$BACKEND_URL}/pharmacy-drugs/show/${this.$route.params.uuid}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
						this.stockDetails.uuid = response.data.uuid;
						this.stockDetails.batchNumber = response.data.batchNumber;
						this.stockDetails.entryDate = this.$Moment(response.data.entryDate).format("DD MMM YYYY");
						this.stockDetails.quantity = response.data.quantity;
						this.stockDetails.status = `${response.data.status[0].toUpperCase()}${response.data.status.substring(1)}`;
						this.stockDetails.sellingPrice = response.data.sellingPrice;
						this.stockDetails.totalBuyingPrice = response.data.totalBuyingPrice;
						this.stockDetails.manufacturedDate = this.$Moment(response.data.manufacturedDate).format("DD MMM YYYY");
						this.stockDetails.expiryDate = this.$Moment(response.data.expiryDate).format("DD MMM YYYY");
                    }
					
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    // eslint-disable-next-line no-console
                    // console.log(error)

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                });
            },


			async onEditItem() {
				// this.form = this.$form.createForm(this, { name: 'newItemForm' })
				let item = this.stockDetails;
				this.newItem.isCreate = false;
				this.newItem.showModal = true;
                this.newItem.uuid = item.uuid;
                this.newItem.companyUuid = this.userDetails.company.uuid;
				this.newItem.loading = false;
				// eslint-disable-next-line no-console
				// console.log(item)
				setTimeout(() => {
					this.form.setFieldsValue({
						batchNumber: item.batchNumber,
						entryDate: item.entryDate,
						quantity: item.quantity,
						sellingPrice: item.sellingPrice,
						totalBuyingPrice: item.totalBuyingPrice,
						manufacturedDate: item.manufacturedDate,
						expiryDate: item.expiryDate,
					});
				}, 10);

            },

			async handleSubmit() {

				this.form.validateFields((err, values) => {
					if ( !err ) {

						this.newItem.loading = true;

						// eslint-disable-next-line no-console
						console.log(this.form)
			
						let url = `${this.$BACKEND_URL}/pharmacy-drugs/${this.newItem.uuid}`

						let payload = values;

						payload.entryDate = this.$Moment(values.entryDate).format("YYYY-MM-DD")
						payload.manufacturedDate = this.$Moment(values.manufacturedDate).format("YYYY-MM-DD")
						payload.expiryDate = this.$Moment(values.expiryDate).format("YYYY-MM-DD")

						this.$AXIOS.patch(url, payload).then(response => {

							if (response.status >= 200 && response.status < 210) {

								this.form.resetFields()

								this.newItem.uuid = null;
								this.newItem.name = null;
								this.newItem.codeName = null;
								this.newItem.description = null;
								this.newItem.isCreate = true;
								this.newItem.loading = false;
								this.newItem.showModal = false;

								this.notify.text = response.data.message
								this.notify.type = "success"

								this.$notify(this.notify)

							}

							this.getBatchDetails()
			
						}).catch(error => {
						
							this.newItem.loading = false;
				
							if(error.response != null && error.response.status == 401) {
								this.$router.push(`/`);
							}

							this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
							this.notify.type = "error"

							this.$notify(this.notify)
						});
					}
				})
				
			},


			showDeletionConfirmation(uuid) {
				this.deleteDataItem.uuid = uuid;
				this.deleteDataItem.showModal = true;
			},

			cancelItemDeletion() {
				this.deleteDataItem.uuid = null;
				this.deleteDataItem.showModal = false;
			},

			onDeleteItem() {

				this.deleteDataItem.loading = true;

                let url = `${this.$BACKEND_URL}/drugs/${this.deleteDataItem.uuid}`;

                this.$AXIOS.delete(url).then((response) => {
                        
					this.deleteDataItem.uuid = null;
					this.deleteDataItem.showModal = false;
                
                    this.deleteDataItem.loading = false;

					this.notify.text = response.data.message;
                    this.notify.type = "success"

                    this.$notify(this.notify)

					this.getPharmacyMedicines();

                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                    this.pagination.loading = false;
                });
			},



			/// TRACKINGS
			onLaunchApprovalTrackingModal(item) {
                this.tracking.trackingUuid = item.uuid;
                this.tracking.status = "approved";
                this.tracking.comments = "";
                this.tracking.nextStageNumber = parseInt(item.stage.stageNumber) + 1;
                this.tracking.isCreate = true;
                this.tracking.showModal = true;
            },


			onLaunchRejectTrackingModal(item) {
                this.trackingRejection.trackingUuid = item.uuid;
                this.trackingRejection.status = "rejected";
                this.trackingRejection.comments = "";
                this.trackingRejection.nextStageNumber = parseInt(item.stage.stageNumber) - 1;
                this.trackingRejection.isCreate = true;
                this.trackingRejection.showModal = true;
            },


			async getTrackings() {

                const currentPage = 1;
				const perPage = 1000;

                let url = `${this.$BACKEND_URL}/pharmacy-drugs/trackings/batch/${this.$route.params.uuid}?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.records = response.data.data;
                    }
					
                }).catch((error) => {
					
                    this.notify.text = error.response &&  error.response.data ? error.response.data.message : "Something went wrong, try again please!";
                    this.notify.type = "error"
                    this.$notify(this.notify)

                });
            },



			onSubmitTrackingApproval() {

				this.tracking.loading = true;

                let url = `${this.$BACKEND_URL}/pharmacy-drugs/approve-stage`;

				this.tracking.pharmacyDrugUuid = this.stockDetails.uuid;

                this.$AXIOS.post(url, this.tracking).then((response) => {
                    if (response.status >= 200 && response.status < 210) {

						this.tracking.loading = false;
                        this.tracking.trackingUuid = null;
                        this.tracking.status = null;
                        this.tracking.comments = null;
                        this.tracking.nextStageNumber = null;
                        this.tracking.showModal = false;


                        this.notify.message = response.data.message;
                        this.notify.type = "success"
                        this.$notify(this.notify)
                    }

					this.tracking.loading = false;
					this.getBatchDetails();
                    this.getTrackings();
                })
                .catch((error) => {
					this.tracking.loading = false;
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }
                    this.notify.text = error.response &&  error.response.data ? error.response.data.message : "Something went wrong, try again please!";
                    this.notify.type = "error"
                    this.$notify(this.notify)
                });
            
            },


			onSubmitTrackingRejection() {

				this.tracking.loading = true;

                let url = `${this.$BACKEND_URL}/pharmacy-drugs/reject-stage`;

                this.$AXIOS.post(url, this.trackingRejection).then((response) => {
                    if (response.status >= 200 && response.status < 210) {

						this.trackingRejection.loading = false;
                        this.trackingRejection.trackingUuid = null;
                        this.trackingRejection.status = null;
                        this.trackingRejection.comments = null;
                        this.trackingRejection.nextStageNumber = null;
                        this.trackingRejection.showModal = false;


                        this.notify.message = response.data.message;
                        this.notify.type = "success"
                        this.$notify(this.notify)
                    }

					this.trackingRejection.loading = false;

					this.getBatchDetails();
                    this.getTrackings();
                })
                .catch((error) => {
					this.trackingRejection.loading = false;
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }
                    this.notify.text = error.response &&  error.response.data ? error.response.data.message : "Something went wrong, try again please!";
                    this.notify.type = "error"
                    this.$notify(this.notify)
                });
            
            },


		},
    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }
</style>